import { useMemo } from 'react'
import { Checkbox, FormInputLabeled, H2, H3, NewTabIcon, RadioButtonGroup, TextMuted } from 'common'
import SquareDown from 'common/atoms/Icons/SquareDown'
import { Accordion, Card, Col, Form, Row } from 'react-bootstrap'
import FieldInfo from 'shared_components/FieldInfo'

import { SportDataType } from './SportData'
import TanzportTooltip from './TanzportTooltip'

import styles from './FormSportData.module.scss'

interface Props {
    values: SportDataType
    sportData: SportDataType
    initialValues?: SportDataType
    dateOfBirth?: string
    today: Date
    errors?: Record<string, unknown>
}

const FormSportData = ({ values, initialValues, sportData, errors }: Props): JSX.Element => {
    const globalError = useMemo(
        () => errors && typeof errors === 'string' && <p className={styles.error}>{errors}</p>,
        [errors],
    )

    return (
        <>
            <H2 className="m-0">Abteilungen/Sparten</H2>
            <p className="p-0">
                Übersicht über unser{' '}
                <a href="https://www.niendorfer-tsv.de/sportsuche/" target="_blank" rel="noopener noreferrer">
                    Sportprogramm
                </a>{' '}
                sowie all unseren{' '}
                <a href="https://www.niendorfer-tsv.de/beitraege/" target="_blank" rel="noopener noreferrer">
                    Beiträgen
                </a>{' '}
                (bitte Verlinkung folgen)
            </p>

            {globalError}

            <Row>
                {!initialValues?.sportData.adyton && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.adyton"
                            inputComponent={
                                <Checkbox
                                    label={
                                        <>
                                            Adyton
                                            <FieldInfo trigger="click" tipId="memberId">
                                                <div>
                                                    <p>
                                                        Die Aufnahmegebühren sowie sonstige Gebühren (Fitnesscheck etc)
                                                        sind mit dem ersten Beitragseinzug fällig. Die jeweilige Höhe
                                                        des Beitrags, der Aufnahmegebühr und sonstige Gebühren werden
                                                        nach der im Sportprogramm enthaltenen Beitrags- und
                                                        Gebührenauflistung geregelt und wird nicht gesondert mitgeteilt.
                                                    </p>
                                                </div>
                                            </FieldInfo>
                                            <a
                                                href="https://www.niendorfer-tsv.de/beitraege/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <NewTabIcon />
                                            </a>
                                        </>
                                    }
                                    name="sportData.adyton"
                                    value={values.sportData.adyton}
                                    disabled={values.endingData.adyton !== undefined}
                                    className="adyton"
                                />
                            }
                        />
                        {values.sportData.adyton && (
                            <div className={styles.ddlRadioBox}>
                                <FormInputLabeled
                                    controlId="sportData.adytonAddon"
                                    inputComponent={
                                        <RadioButtonGroup
                                            name="sportData.adytonAddon"
                                            options={[
                                                { value: 'gold', label: 'Gold (Fitness/Kurse/Wellness)' },
                                                { value: 'silber', label: 'Silber (Kurse/Wellness)' },
                                                { value: 'bronze', label: 'Bronze (Wellness)' },
                                            ]}
                                            value={values.sportData.adytonAddon}
                                            disabled={
                                                values.endingData.adyton !== undefined || sportData?.sportData.adyton
                                            }
                                            className={[styles.list, 'adytonAddon'].join(' ')}
                                        />
                                    }
                                />
                            </div>
                        )}
                    </Col>
                )}
                {!initialValues?.sportData.badminton && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.badminton"
                            inputComponent={
                                <Checkbox
                                    label="Badminton"
                                    name="sportData.badminton"
                                    value={values.sportData.badminton}
                                    disabled={values.endingData.badminton !== undefined}
                                    className="badminton"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.baseball && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.baseball"
                            inputComponent={
                                <Checkbox
                                    label="Baseball"
                                    name="sportData.baseball"
                                    value={values.sportData.baseball}
                                    disabled={values.endingData.baseball !== undefined}
                                    className="baseball"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.basketball && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.basketball"
                            inputComponent={
                                <Checkbox
                                    label="Basketball"
                                    name="sportData.basketball"
                                    value={values.sportData.basketball}
                                    disabled={values.endingData.basketball !== undefined}
                                    className="basketball"
                                />
                            }
                        />
                    </Col>
                )}

                {!initialValues?.sportData.soccer && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.fussball"
                            inputComponent={
                                <Checkbox
                                    label="Fußball"
                                    name="sportData.soccer"
                                    value={values.sportData.soccer}
                                    disabled={values.endingData.soccer !== undefined}
                                    className="fussball"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.handball && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.handball"
                            inputComponent={
                                <Checkbox
                                    label="Handball"
                                    name="sportData.handball"
                                    value={values.sportData.handball}
                                    disabled={values.endingData.handball !== undefined}
                                    className="handball"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.judo && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.judo"
                            inputComponent={
                                <Checkbox
                                    label="Judo"
                                    name="sportData.judo"
                                    value={values.sportData.judo}
                                    disabled={values.endingData.judo !== undefined}
                                    className="judo"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.jujutsu && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.jujutsu"
                            inputComponent={
                                <Checkbox
                                    label="JuJutsu"
                                    name="sportData.jujutsu"
                                    value={values.sportData.jujutsu}
                                    disabled={values.endingData.jujutsu !== undefined}
                                    className="jujutsu"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.karate && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.karate"
                            inputComponent={
                                <Checkbox
                                    label="Karate"
                                    name="sportData.karate"
                                    value={values.sportData.karate}
                                    disabled={values.endingData.karate !== undefined}
                                    className="karate"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.kungfu && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.kungfu"
                            inputComponent={
                                <Checkbox
                                    label="Kung-Fu"
                                    name="sportData.kungfu"
                                    value={values.sportData.kungfu}
                                    disabled={values.endingData.kungfu !== undefined}
                                    className="kungfu"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.chess && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.chess"
                            inputComponent={
                                <Checkbox
                                    label="Schach"
                                    name="sportData.chess"
                                    value={values.sportData.chess}
                                    disabled={values.endingData.chess !== undefined}
                                    className="chess"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.swimming && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.swimming"
                            inputComponent={
                                <Checkbox
                                    label={
                                        <>
                                            Schwimmen
                                            <FieldInfo trigger="click" tipId="memberId">
                                                <div>
                                                    <p>
                                                        Hier handelt es sich um das Angebot unserer Schwimmabteilung,
                                                        nicht um unserer Schwimmschule Niendorf.
                                                    </p>
                                                    <p>
                                                        Die Anmeldung für die Schwimmschule erfolgt{' '}
                                                        <a
                                                            href="https://kursbuchungen.niendorfer-tsv.de"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            hier
                                                        </a>
                                                    </p>
                                                </div>
                                            </FieldInfo>
                                        </>
                                    }
                                    name="sportData.swimming"
                                    value={values.sportData.swimming}
                                    disabled={values.endingData.swimming !== undefined}
                                    className="swimming"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.tennis && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.tennis"
                            inputComponent={
                                <Checkbox
                                    label={
                                        <>
                                            Tennis
                                            <FieldInfo trigger="click" tipId="memberId">
                                                <div>
                                                    <p>
                                                        Bitte beachten Sie die gesonderten Regelungen zur
                                                        Tennismitgliedschaft. Informationen entnehmen Sie bitte unserer
                                                        Seite zu den Tennisgebühren.
                                                    </p>
                                                </div>
                                            </FieldInfo>
                                            <a
                                                href="https://www.niendorfer-tsv.de/beitraege/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <NewTabIcon />
                                            </a>
                                        </>
                                    }
                                    name="sportData.tennis"
                                    value={values.sportData.tennis}
                                    disabled={values.endingData.tennis !== undefined}
                                    className="tennis"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.theatre && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.theatre"
                            inputComponent={
                                <Checkbox
                                    label="Theater / Laienspiel"
                                    name="sportData.theatre"
                                    value={values.sportData.theatre}
                                    disabled={values.endingData.theatre !== undefined}
                                    className="theatre"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.tableTennis && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.tableTennis"
                            inputComponent={
                                <Checkbox
                                    label="Tischtennis"
                                    name="sportData.tableTennis"
                                    value={values.sportData.tableTennis}
                                    disabled={values.endingData.tableTennis !== undefined}
                                    className="tableTennis"
                                />
                            }
                        />
                    </Col>
                )}
                {!initialValues?.sportData.volleyball && (
                    <Col xs={6} md={4} lg={3} className="p-3">
                        <FormInputLabeled
                            controlId="sportData.volleyball"
                            inputComponent={
                                <Checkbox
                                    label="Volleyball"
                                    name="sportData.volleyball"
                                    value={values.sportData.volleyball}
                                    disabled={values.endingData.volleyball !== undefined}
                                    className="volleyball"
                                />
                            }
                        />
                    </Col>
                )}
            </Row>

            {/* Accordion stuff */}
            <Row>
                <Col xs={6} md={4} lg={3} className="p-3">
                    <Accordion>
                        <>
                            <Accordion.Toggle as={Form.Label} eventKey="0" className="accordion-toggle-hover">
                                <SquareDown width={14} /> Einrad
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card className={[styles.card, 'mt-2 mb-4'].join(' ')}>
                                    <Card.Body className={styles.cardBody}>
                                        <>
                                            <Row>
                                                {!initialValues?.sportData.onewheelAddons.onewheel && (
                                                    <Col className="pb-3">
                                                        <FormInputLabeled
                                                            controlId="sportData.onewheelAddons.onewheel"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Einrad"
                                                                    name="sportData.onewheelAddons.onewheel"
                                                                    value={values.sportData.onewheelAddons.onewheel}
                                                                    disabled={
                                                                        values.endingData.onewheelAddons.onewheel !==
                                                                        undefined
                                                                    }
                                                                    className="onewheelAddonsOnewheel"
                                                                />
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.onewheelAddons.hockey && (
                                                    <Col>
                                                        <FormInputLabeled
                                                            controlId="sportData.onewheelAddons.hockey"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Einradhockey"
                                                                    name="sportData.onewheelAddons.hockey"
                                                                    value={values.sportData.onewheelAddons.hockey}
                                                                    disabled={
                                                                        values.endingData.onewheelAddons.hockey !==
                                                                        undefined
                                                                    }
                                                                    className="onewheelAddonsOneHockey"
                                                                />
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </>
                                    </Card.Body>
                                </Card>
                            </Accordion.Collapse>
                        </>
                    </Accordion>
                </Col>
                <Col xs={6} md={4} lg={3} className="p-3">
                    <Accordion>
                        <>
                            <Accordion.Toggle as={Form.Label} eventKey="0" className="accordion-toggle-hover">
                                <SquareDown width={14} /> Leichtathletik
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card className={[styles.card, 'mt-2 mb-4'].join(' ')}>
                                    <Card.Body className={styles.cardBody}>
                                        <>
                                            <Row>
                                                {!initialValues?.sportData.athleticsAddons.athletics && (
                                                    <Col className="pb-3">
                                                        <FormInputLabeled
                                                            controlId="sportData.athleticsAddons.athletics"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Leichtathletik"
                                                                    name="sportData.athleticsAddons.athletics"
                                                                    value={values.sportData.athleticsAddons.athletics}
                                                                    disabled={
                                                                        values.endingData.athleticsAddons.athletics !==
                                                                        undefined
                                                                    }
                                                                    className="athleticsAddonsAthletics"
                                                                />
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row>
                                                {!initialValues?.sportData.athleticsAddons.walking && (
                                                    <Col>
                                                        <FormInputLabeled
                                                            controlId="sportData.athleticsAddons.walking"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Walken"
                                                                    name="sportData.athleticsAddons.walking"
                                                                    value={values.sportData.athleticsAddons.walking}
                                                                    disabled={
                                                                        values.endingData.athleticsAddons.walking !==
                                                                        undefined
                                                                    }
                                                                    className="athleticsAddonsWalking"
                                                                />
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </>
                                    </Card.Body>
                                </Card>
                            </Accordion.Collapse>
                        </>
                    </Accordion>
                </Col>
                <Col xs={12} md={12} lg={6} className="p-3">
                    <Accordion>
                        <>
                            <Accordion.Toggle as={Form.Label} eventKey="0" className="accordion-toggle-hover">
                                <SquareDown width={14} /> Tanzsport
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card className={[styles.card, 'mt-2 mb-4'].join(' ')}>
                                    <Card.Body className={styles.cardBody}>
                                        <>
                                            <Row>
                                                {!initialValues?.sportData.danceAddon_ballett && (
                                                    <Col xs={12} md={6} className="pb-3">
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_ballett"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Ballett"
                                                                    name="sportData.danceAddon_ballett"
                                                                    value={values.sportData.danceAddon_ballett}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_ballett !==
                                                                        undefined
                                                                    }
                                                                    className="dancingBallett"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_bellydance && (
                                                    <Col xs={12} md={6} className="pb-3">
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_bellydance"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Bauchtanz"
                                                                    name="sportData.danceAddon_bellydance"
                                                                    value={values.sportData.danceAddon_bellydance}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_bellydance !==
                                                                        undefined
                                                                    }
                                                                    className="dancingBellydance"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_breakdance && (
                                                    <Col xs={12} md={6} className="pb-3">
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_breakdance"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Breakdance"
                                                                    name="sportData.danceAddon_breakdance"
                                                                    value={values.sportData.danceAddon_breakdance}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_breakdance !==
                                                                        undefined
                                                                    }
                                                                    className="dancingBreakdance"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_hiphop && (
                                                    <Col xs={12} md={6} className="pb-3">
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_hiphop"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Hip Hop"
                                                                    name="sportData.danceAddon_hiphop"
                                                                    value={values.sportData.danceAddon_hiphop}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_hiphop !==
                                                                        undefined
                                                                    }
                                                                    className="dancingHiphop"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_creativeChildrendance && (
                                                    <Col xs={12} md={6} className="pb-3">
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_creativeChildrendance"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Kreativer Kindertanz"
                                                                    name="sportData.danceAddon_creativeChildrendance"
                                                                    value={
                                                                        values.sportData
                                                                            .danceAddon_creativeChildrendance
                                                                    }
                                                                    disabled={
                                                                        values.endingData
                                                                            ?.danceAddon_creativeChildrendance !==
                                                                        undefined
                                                                    }
                                                                    className="dancingcreativeChildrendance"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_linedance && (
                                                    <Col xs={12} md={6} className="pb-3">
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_linedance"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Linedance"
                                                                    name="sportData.danceAddon_linedance"
                                                                    value={values.sportData.danceAddon_linedance}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_linedance !==
                                                                        undefined
                                                                    }
                                                                    className="dancingLinedance"
                                                                />
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_pairdance && (
                                                    <Col xs={12} md={6} className="pb-3">
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_pairdance"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Paartanz"
                                                                    name="sportData.danceAddon_pairdance"
                                                                    value={values.sportData.danceAddon_pairdance}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_pairdance !==
                                                                        undefined
                                                                    }
                                                                    className="dancingPairdance"
                                                                />
                                                            }
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_tapdance && (
                                                    <Col xs={12} md={6} className="pb-3">
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_tapdance"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Stepptanz"
                                                                    name="sportData.danceAddon_tapdance"
                                                                    value={values.sportData.danceAddon_tapdance}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_tapdance !==
                                                                        undefined
                                                                    }
                                                                    className="dancingTapdance"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                                {!initialValues?.sportData.danceAddon_contemporary && (
                                                    <Col xs={12} md={6}>
                                                        <FormInputLabeled
                                                            controlId="sportData.danceAddon_contemporary"
                                                            inputComponent={
                                                                <Checkbox
                                                                    label="Jazz / Modern+Contemporary"
                                                                    name="sportData.danceAddon_contemporary"
                                                                    value={values.sportData.danceAddon_contemporary}
                                                                    disabled={
                                                                        values.endingData?.danceAddon_contemporary !==
                                                                        undefined
                                                                    }
                                                                    className="dancingContemporary"
                                                                />
                                                            }
                                                            info={<TanzportTooltip />}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </>
                                    </Card.Body>
                                </Card>
                            </Accordion.Collapse>
                        </>
                    </Accordion>
                </Col>
            </Row>

            <Row>
                {!initialValues?.sportData.freeAthletics && (
                    <Col className="p-3">
                        <FormInputLabeled
                            controlId="sportData.freeAthletics"
                            inputComponent={
                                <Checkbox
                                    label="Turnen, Freizeit- und Gesundheitssport (z.B. Kinderturnen für Kinder über 4 Jahre, Seniorensport, BBP)"
                                    name="sportData.freeAthletics"
                                    value={values.sportData.freeAthletics}
                                    disabled={values.endingData.freeAthletics !== undefined}
                                    className="freeAthletics"
                                />
                            }
                        />
                    </Col>
                )}
            </Row>

            {/* Light blue box */}
            <Row className={styles.box}>
                <Col>
                    <Row>
                        <Col>
                            <TextMuted>
                                <p>
                                    <b>
                                        Folgende{' '}
                                        <a
                                            href="https://www.niendorfer-tsv.de/beitraege/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Sportangebote der TFG
                                        </a>{' '}
                                        sind zusatzbeitragspflichtig.
                                    </b>
                                    <br />
                                    Bei mehrfacher, wöchentlicher Teilnahme ist auch ein Mehrfaches des Zusatzbeitrags
                                    zu entrichten:
                                </p>
                            </TextMuted>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <H3>Für Kinder bis 4 Jahren</H3>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        {!initialValues?.sportData.childAthletics && (
                            <Col xs={6} md={4} lg={3} className="pb-3">
                                <FormInputLabeled
                                    controlId="sportData.childAthletics"
                                    inputComponent={
                                        <Checkbox
                                            label="Eltern-Kind-Turnen (für Kinder bis 4 Jahren)"
                                            name="sportData.childAthletics"
                                            value={values.sportData.childAthletics}
                                            disabled={values.endingData.childAthletics !== undefined}
                                            className="childAthletics"
                                            data-testid={`formCheckbox_sportData.childAthletics`}
                                        />
                                    }
                                />
                            </Col>
                        )}
                    </Row>

                    <Row>
                        <Col>
                            <H3>Für Kinder bis 17 Jahren</H3>
                        </Col>
                    </Row>
                    <Row>
                        {!initialValues?.sportData.sportgymnastik && (
                            <Col xs={6} md={4} lg={3} className="pb-3">
                                <FormInputLabeled
                                    controlId="sportData.sportgymnastik"
                                    inputComponent={
                                        <Checkbox
                                            label="Rhythmische Sportgymnastik"
                                            name="sportData.sportgymnastik"
                                            value={values.sportData.sportgymnastik}
                                            disabled={values.endingData.sportgymnastik !== undefined}
                                            className="sportgymnastik"
                                        />
                                    }
                                />
                            </Col>
                        )}
                        {!initialValues?.sportData.trampoline && (
                            <Col xs={6} md={4} lg={3} className="pb-3">
                                <FormInputLabeled
                                    controlId="sportData.trampoline"
                                    inputComponent={
                                        <Checkbox
                                            label="Trampolin"
                                            name="sportData.trampoline"
                                            value={values.sportData.trampoline}
                                            disabled={values.endingData.trampoline !== undefined}
                                            className="trampoline"
                                        />
                                    }
                                />
                            </Col>
                        )}
                    </Row>

                    {/*TODO: remove if necessary {isAdult(age) && (*/}
                    <Row className="pt-3">
                        <Col>
                            <H3>Für Erwachsene</H3>
                        </Col>
                    </Row>
                    <Row>
                        {!initialValues?.sportData.krebsSport && (
                            <Col xs={6} md={4} lg={3} className="pb-3">
                                <FormInputLabeled
                                    controlId="sportData.krebsSport"
                                    inputComponent={
                                        <Checkbox
                                            label="Krebsnachsorge ohne Verordnung"
                                            name="sportData.krebsSport"
                                            value={values.sportData.krebsSport}
                                            disabled={values.endingData.krebsSport !== undefined}
                                            className="krebsSport"
                                        />
                                    }
                                />
                            </Col>
                        )}
                        {!initialValues?.sportData.lungSport && (
                            <Col xs={6} md={4} lg={3} className="pb-3">
                                <FormInputLabeled
                                    controlId="sportData.lungSport"
                                    inputComponent={
                                        <Checkbox
                                            label="Lungensport ohne Verordnung"
                                            name="sportData.lungSport"
                                            value={values.sportData.lungSport}
                                            disabled={values.endingData.lungSport !== undefined}
                                            className="lungSport"
                                        />
                                    }
                                />
                            </Col>
                        )}
                        {!initialValues?.sportData.osteoporose && (
                            <Col xs={6} md={4} lg={3} className="pb-3">
                                <FormInputLabeled
                                    controlId="sportData.osteoporose"
                                    inputComponent={
                                        <Checkbox
                                            label="Osteoporose"
                                            name="sportData.osteoporose"
                                            value={values.sportData.osteoporose}
                                            disabled={values.endingData.osteoporose !== undefined}
                                            className="osteoporose"
                                        />
                                    }
                                />
                            </Col>
                        )}
                        {!initialValues?.sportData.backFit && (
                            <Col xs={6} md={4} lg={3} className="pb-3">
                                <FormInputLabeled
                                    controlId="sportData.backFit"
                                    inputComponent={
                                        <Checkbox
                                            label="Rückenfit"
                                            name="sportData.backFit"
                                            value={values.sportData.backFit}
                                            disabled={values.endingData.backFit !== undefined}
                                            className="backFit"
                                        />
                                    }
                                />
                            </Col>
                        )}
                        {!initialValues?.sportData.yoga && (
                            <Col xs={6} md={4} lg={3} className="pb-3">
                                <FormInputLabeled
                                    controlId="sportData.yoga"
                                    inputComponent={
                                        <Checkbox
                                            label="Yoga"
                                            name="sportData.yoga"
                                            value={values.sportData.yoga}
                                            disabled={values.endingData.yoga !== undefined}
                                            className="yoga"
                                        />
                                    }
                                />
                            </Col>
                        )}
                    </Row>
                    {/*)}*/}
                </Col>
            </Row>
        </>
    )
}

export default FormSportData
